


































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import moment from 'moment';
import { ModelSelect } from 'vue-search-select';
import Modal from '@/components/common/Modal.vue';
import { Payment } from '@/models/investments/Investment';

@Component({
  components: {
    ModelSelect,
    Modal,
  },
})
export default class PaymentActionModal extends Vue {
  @Prop({ default: undefined }) selectedPayment!: Payment;
  @Prop() paymentAction!: 'remove' | 'refund' | 'markAsPaid';
  @Prop() isProcessing!: boolean;

  @Action deletePayment!: (data: { investmentId: string, paymentId: string }) => {};
  @Action markPaymentAsPaid!: (data: { investmentId: string, paymentId: string }) => {};
  @Action deleteGift!: (data: { investmentId: string, paymentId: string, giftId: string }) => {};
  @Action refundPayment!: (data: { investmentId: string, paymentId: string, paynlTransactionId: string, assetId: string }) => {};

  selectedYear: number | null = null;
  selectedMonth: number | null = null;

  get paymentDate(): Date {
    const timeToUse = (this.selectedPayment.paymentDateTime || this.selectedPayment.createdDateTime);
    return timeToUse.toDate();
  }

  /**
   * Calculates the first date from when the underlying payment can be ended
   * @example payment created at 1st Nov, 2019 => { year: 2019, month: 11 }
   * @example payment created at 1st Dec, 2019 => { year: 2020, month: 0 }
   */
  get firstDateToEnd(): { year: number, month: number } {
    const nextMonthDate = new Date(this.paymentDate.getFullYear(), this.paymentDate.getMonth() + 1);
    return { year: nextMonthDate.getFullYear(), month: nextMonthDate.getMonth() };
  }

  get months(): { value: number, text: string}[] {
    let monthList = moment.months().map((mon, i) => ({ value: i, text: mon }));

    // Filter the month list to begin from the next month of the payment if the first year is selected
    if (this.selectedYear && this.selectedYear === this.firstDateToEnd.year) {
      monthList = monthList.filter((mon): boolean => mon.value >= this.firstDateToEnd.month);
    }

    const currentDate = new Date();
    if (this.selectedYear && this.selectedYear === currentDate.getFullYear()) {
      monthList = monthList.filter((mon): boolean => mon.value <= currentDate.getMonth());
    }

    return monthList;
  }

  get years(): { value: number, text: string}[] {
    const currentYear = (new Date()).getFullYear();
    return Array.from({ length: currentYear - this.paymentDate.getFullYear() + 1 }, (v, i) => this.firstDateToEnd.year + i).map(
      (year): { value: number, text: string } => ({ value: year, text: year.toString() }),
    );
  }

  // This watcher clears the month field if due to a change in the chosen year
  // the previously selected month is not available anymore in the dropdown list
  @Watch('selectedYear')
  onYearChange(newYear: number) {
    if (this.selectedMonth === null) {
      return;
    }

    const currentDate = new Date();
    const lowerConstraintApplies = newYear === this.firstDateToEnd.year && this.selectedMonth < this.firstDateToEnd.month;
    const upperConstraintApplies = newYear === currentDate.getFullYear() && this.selectedMonth > currentDate.getMonth();

    if (lowerConstraintApplies || upperConstraintApplies) {
      this.selectedMonth = null;
    }
  }

  get paymentCannotBeEnded(): boolean {
    const { year, month } = this.firstDateToEnd;
    return new Date(year, month) > new Date();
  }

  get areFieldsMissing(): boolean {
    return this.selectedMonth === null || this.selectedYear == null;
  }

  handleSubmit(): void {
    if (this.paymentAction === 'remove') {
      if (!this.selectedPayment.gift) {
        this.deletePayment({
          investmentId: this.selectedPayment.investment.id!,
          paymentId: this.selectedPayment.id!,
        });
      } else {
        this.deleteGift({
          investmentId: this.selectedPayment.investment.id!,
          paymentId: this.selectedPayment.id!,
          giftId: this.selectedPayment.gift!.id,
        });
      }

      return;
    }

    if (this.paymentAction === 'markAsPaid') {
      this.markPaymentAsPaid({
        investmentId: this.selectedPayment.investment.id!,
        paymentId: this.selectedPayment.id!,
      });
      return;
    }

    this.refundPayment({
      investmentId: this.selectedPayment.investment.id!,
      paymentId: this.selectedPayment.id!,
      paynlTransactionId: this.selectedPayment.providerData.id!,
      assetId: this.selectedPayment.asset.id!,
    });
  }
}
