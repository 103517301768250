
















































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Action, State as StateClass } from 'vuex-class';
import { ADD_TOAST_MESSAGE as addToastMessage } from 'vuex-toast';
import moment from 'moment';
import BigNumber from 'bignumber.js';
import { State } from '@/models/State';
import { Payment } from '@/models/investments/Investment';
import { Investor } from '@/models/users/User';
import FormInput, { FormIcons } from '@/components/common/form-elements/FormInput.vue';
import FormDatePicker from '@/components/common/form-elements/FormDatePicker.vue';
import { DataContainerStatus } from '@/models/Common';

@Component({
  components: {
    FormInput,
    FormDatePicker,
  },
})
export default class ModifySubscriptionModal extends Vue {
  FormIcons = FormIcons;
  formData = {
    modifySubDate: new Date(),
    bankAccount: '',
    mandateBankAccount: '',
    sharesAmount: 0,
    sharesPrice: 0,
  }
  isInactive = false;
  hasError = false;

  @Prop() data: any;
  @Prop() subscription!: [string, { status: string, payment: any}];

  @Action getSubscription!: ({ mandateId, assetId }: { mandateId: string, assetId: string }) => Promise<any>;
  @Action(addToastMessage) addToastMessage!: Function;

  @StateClass('subscription') subscriptionState!: State['subscription'];

  @Watch('formData', { deep: true })
  onNewFormData(newVal: [string, { status: string, payment: any }]) {
    this.$emit('update-parent-object', newVal);
  }

  @Watch('subscription', { deep: true, immediate: true })
  onNewSubscription(newVal: [string, { status: string, payment: any }]) {
    this.getSubscription({ mandateId: newVal[0], assetId: newVal[1].payment.asset.id });
  }

  @Watch('subscriptionState.status', { immediate: true })
  onNewSubscriptionData(newStatus: DataContainerStatus): void {
    if (this.subscriptionState?.operation === 'getSubscription') {
      this.hasError = false;
      if (newStatus === DataContainerStatus.Error) {
        this.addToastMessage({
          text: 'There was a problem retrieving this subscription from Pay.nl',
          type: 'error',
        });
        this.hasError = true;
      } else if (newStatus === DataContainerStatus.Success) {
        this.hasError = false;
        // prefilling data
        this.formData = {
          // modifySubDate: this.payment.providerData.processDate.toDate(),
          modifySubDate: new Date(),
          bankAccount: this.subscriptionState.payload.data.bankaccountNumber,
          mandateBankAccount: this.subscriptionState.payload.data.bankaccountNumber,
          sharesAmount: this.payment.providerData.metadata.sharesAmount,
          sharesPrice: new BigNumber(this.payment.providerData.metadata.euroAmount)
            .dividedBy(this.payment.providerData.metadata.sharesAmount).toNumber(),
        };
      }
    }
  }

  get statusColor(): string {
    if (this.hasError === true) {
      return 'status__error';
    }

    return '';
  }

  get isLoading(): boolean {
    return this.subscriptionState?.status === DataContainerStatus.Processing;
  }

  get disabledButton(): boolean {
    if (this.hasError === true) {
      return this.isInactive === true;
    }

    return this.isInactive === false;
  }

  get payment(): Payment {
    return this.subscription && this.subscription[1]?.payment;
  }

  get investor(): Investor {
    return this.subscription && this.subscription[1]?.payment.investor;
  }

  get totalPrice(): number {
    return new BigNumber(this.formData.sharesAmount || 0).times(this.formData.sharesPrice || 0).toNumber();
  }

  disabledDates(date: Date): boolean {
    return date < moment().subtract(1, 'd').toDate();
  }
}
