


























import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({})
export default class ModifySubscriptionModal extends Vue {
  modalIsClosed = false;
  @Prop() data: any;
  @Prop() subscription!: [string, string];
  @Prop() selectedInvestment: any;

  closeModal(): void {
    this.modalIsClosed = true;
  }

  get userName(): any {
    return `${this.selectedInvestment.investor.name} ${this.selectedInvestment.investor.surname}`;
  }
}
