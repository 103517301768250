


































import { Component, Vue } from 'vue-property-decorator';
import { State as StateClass } from 'vuex-class';
import { State } from '@/models/State';
import Tabs from '@/components/common/Tabs.vue';

@Component({
  components: {
    Tabs,
  },
})

export default class Investments extends Vue {
  @StateClass boundInvestments!: State['boundInvestments'];
  @StateClass loanPayments!: State['loanPayments'];

  get tabs(): { title: string, routeName: string, length?: number, badge?: boolean }[] {
    return [
      {
        title: 'All investments',
        routeName: 'investments-all',
        length: this.investmentsCount,
        badge: true,
      },
      {
        title: 'Loans',
        routeName: 'investments-loans',
        length: this.loanPaymentsCount,
        badge: true,
      },
    ];
  }

  get investmentsCount(): number {
    return this.boundInvestments?.length || 0;
  }

  get loanPaymentsCount(): number {
    return this.loanPayments.length || 0;
  }
}
