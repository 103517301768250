





































































import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
})
export default class InvestmentsTable extends Vue {
  @Prop() columns: any
  @Prop() data: any
  @Prop() options: any

  get transformedOptions(): any {
    return {
      ...this.options,
      ...(this.$route.query.page && {
        initialPage: Number(this.$route.query.page),
      }),
    };
  }

  onPagination(index: number): void {
    // Using history instead of vue-router here
    // because vue router makes the page actually refresh
    history.replaceState(null, '', `?page=${index}`);
  }
}
