import axios, { AxiosInstance } from 'axios';
import { bloqifyAuth } from './firebase';

let instance: AxiosInstance;

export const firebaseAxiosInstance = async (): Promise<AxiosInstance> => {
  const token = bloqifyAuth.currentUser ? `Bearer ${await bloqifyAuth.currentUser.getIdToken()}` : undefined;

  if (instance && instance.defaults.headers.common.Authorization === token) {
    return instance;
  }

  instance = axios.create({
    baseURL: process.env.NODE_ENV === 'development' ?
      `http://localhost:5002/${process.env.VUE_APP_BLOQIFY_FIREBASE_PROJECT_ID}/${process.env.VUE_APP_BLOQIFY_FIREBASE_CLOUD_FUNCTIONS_REGION}` :
      `https://${process.env.VUE_APP_BLOQIFY_FIREBASE_CLOUD_FUNCTIONS_REGION}-${process.env.VUE_APP_BLOQIFY_FIREBASE_PROJECT_ID}.cloudfunctions.net`,
  });
  instance.defaults.headers.common.Authorization = token;

  return instance;
};
